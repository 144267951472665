import React from 'react';
import { Helmet } from 'react-helmet';
import Obfuscate from 'react-obfuscate';

import Layout from '../components/layout';

export default function ContactPage() {
  return (
    <>
      <Helmet>
        <title>Contact | Two Thumbs Up</title>
      </Helmet>
      <Layout isHome={false}>
        <article className="prose">
          <h1>Two Thumbs Up</h1>
          <p>
            1671 Riverview Drive, Suite 104
            <br />
            Lewisville, TX 75056
          </p>

          <p>
            <Obfuscate className="no-underline hover:underline" tel="469 939 2310" />
          </p>
          <p>
            <Obfuscate
              className="no-underline hover:underline"
              email="info@ttutx.com"
              headers={{ subject: 'Contact Two Thumbs Up' }}
            />
          </p>
          <p>
            <Obfuscate
              className="no-underline hover:underline"
              email="stefan@ttutx.com"
              headers={{ subject: 'Contact Two Thumbs Up' }}
            />
          </p>
        </article>
      </Layout>
    </>
  );
}
